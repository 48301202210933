@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@300;400;500;700;900&family=Trispace&display=swap');


body{
    background-color: "#101827";
}




    .card{
        border: 10px;
        
        border-color: rgb(31 41 55);
        margin: 1rem;
        display: flex;
        flex-direction: column;
        --tw-bg-opacity: 1;
        background-color: rgb(31 41 55 / var(--tw-bg-opacity));
        width: 150px;
        height: 200px;
        padding: 1.25rem;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        --tw-backdrop-blur: blur(24px);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        
        transition: all 150ms ease-in-out; 
        
        

    }

    .icon{
        width: 80px;
        height: 80px;
        color: aliceblue;
    }
    .text{
        color: aliceblue;
        margin-top: 4px;
        font-size: 1.125rem/* 18px */;
        line-height: 1.75rem/* 28px */;
        font-family: 'Trispace', sans-serif;
        text-align: center;

    }

    :hover.card{
       
        
        transition: all 150ms ease-in-out; 
        transform: translateY(-10px) scale(1.15);
        border-width: 2px;
        border-color: rgb(29 78 216);
        border-style: solid;
      
       
   
    }
   
    .trispace{
        font-family: 'Trispace', sans-serif;
    }